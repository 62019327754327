.level {
  width: 100%;
  max-width: 500px;
  padding: 1.5rem 0 0;
  &-btn {
    width: 100%;
    max-width: 500px;
    border-radius: 6px;
    background-color: rgba(118, 118, 118, 0.3);
    margin: 0.3rem 0;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8rem;
    }
    &-level {
      color: rgba(140, 163, 154, 0.7);
    }
    &--checked {
      color: #fff;
    }
    &-icon {
      color: $light-font;
      font-size: 1.3rem;
      margin-left: 0.8rem;
      padding-top: 0.3rem;
    }
  }
  &-rules-container {
    border-top: 1px solid rgba(140, 163, 154, 0.3);
  }
  &-rules {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    &-headline {
      color: rgba(140, 163, 154, 0.7);
      font-size: 0.75rem;
    }
    &-text {
      color: #fff;
      font-size: 0.875rem;
      font-weight: 200;
      text-align: left;
      padding: 1.25rem 1.25rem 1.8rem;
    }
    &-text-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      p {
        margin: 0;
      }
    }
  }
}
.arrow-down {
  transform: scaleY(-1);
}

//Radio buttons
/* The container */
.radio-btn-container {
  //display: block;
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  padding-left: 50px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Show the indicator (dot/circle) when checked */
.radio-btn-container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.radio-btn-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $green;
}

/* Hide the browser's default radio button */
.radio-btn-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: -1px;
  left: 12px;
  height: 22px;
  width: 22px;
  border: 2px solid rgba(140, 163, 154, 0.7);
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radio-btn-container input:checked ~ .checkmark {
  background-color: transparent;
  border: 2px solid $green;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

@media screen and (max-height: 500px) {
  .level {
    padding: 0.9rem 0 0;
  }
}
