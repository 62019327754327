.aboutmaps-vektor-tiles {
  text-align: left;
  color: $light-font;
  font-weight: 200;
  font-size: 0.8rem;
  line-height: 1.1rem;
}
.aboutmaps-sm-headline {
  text-align: left;
  color: $light-font;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.4rem;
}
.aboutmaps-list {
  list-style: none;
  padding-left: 0;
}
.aboutmaps-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  margin: 1rem 0;
  border-radius: 6px;
  background-color: rgba(118, 118, 118, 0.2);
  padding: 0.5rem 0.7rem;
  &-img {
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    border-radius: 4px;
    object-fit: cover;
    overflow: hidden;
    & img {
      width: 100%;
      height: auto;
    }
  }
  &-text {
    text-align: left;
    color: #fff;
    font-weight: 200;
    font-size: 0.8rem;
    margin: 0;
  }
  &-headline {
    margin: 0.4rem 0 0.2rem;
  }
  &-description {
    font-size: 0.7rem;
    line-height: 1.2rem;
    margin: 0;
  }
}

@media screen and (max-height: 500px) {
  .aboutmaps-list {
    margin: 0;
  }
  .aboutmaps-content {
    margin: 0 0.7rem;
  }
}
