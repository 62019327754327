.splash-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  min-height: 100vh;
  width: 100vw;
  height: 100%;
  padding: 2rem 1.125rem 3rem;
}

.splash {
  &-container {
    position: relative;
  }
  &-grid {
    margin: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
    &-tile {
      filter: none;
      -webkit-filter: none;
      transition: filter 0.9s;
      height: 70px;
      width: 70px;
      & svg {
        width: 100%;
        height: auto;
      }
    }
  }
}
.splash-two-background {
  justify-content: space-between;
}
.splash-two-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.septima-logo {
  margin: 1rem 0 0;
  width: 9rem;
  & img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

@media screen and (max-height: 500px) {
  .septima-logo {
    width: 7rem;
  }
  .splash-two-container {
    gap: 0.5rem;
  }
}

@media screen and (max-height: 350px) and (max-width: 300px) {
  .septima-logo {
    opacity: 0.7;
    width: 6rem;
  }
  .splash-background {
    padding: 0.6rem 1.125rem 3rem;
  }
}
