.submit {
  &-form {
    max-width: 500px;
    width: 100vw;
    min-width: 100%;
    margin: 0;
  }
  &-form-input-block {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0 0.8rem;
    margin: 0.5rem 0;
  }
  &-form-input-field {
    all: unset;
    color: #fff;
    font-weight: 200;
    font-size: 1.1rem;
    border-radius: 6px;
    max-width: 100vw;
    width: 100%;
    height: 4rem;
    background-color: rgba(118, 118, 118, 0.2);
    caret-color: $green;
    letter-spacing: 1px;
    border: 2px solid transparent;
    &:focus {
      color: #fff;
      border: 2px solid $green;
      background-color: rgba(0, 255, 0, 0.1);
    }
    &:active {
      color: #fff;
      border: 2px solid $green inset;
      background-color: rgba(0, 255, 0, 0.1);
    }
  }
  &-form-input-label {
    color: $light-font;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
    padding: 0.1rem 0.8rem;
  }

  .your-score {
    all: unset;
    color: $green;
    font-size: 1.6rem;
    margin: 0.15rem 0.15rem 1rem;
    text-shadow: $green-box-shadow;
  }
  //Hide up and down arrows in Safari
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .submit-form-input-error {
    padding: 0;
    &-msg {
      font-size: 0.9rem;
      color: #fff;
      font-weight: 100;
      letter-spacing: 0.1rem;
      margin: 0;
    }
  }
  .submit-form-feedback {
    font-size: 0.8rem;
    color: $light-font;
    text-transform: lowercase;
    padding: 0.8rem 1rem;
  }
}
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  // box-shadow: 0 0 0 30px rgba(0, 255, 0, 0.9) inset !important;
  // -webkit-box-shadow: 0 0 0 30px rgba(0, 255, 0, 0.1) inset !important;
  -webkit-text-fill-color: #fff;
}
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;

  // box-shadow: 0 0 0 50px rgba(0, 255, 0, 0.1) inset !important;
  // -webkit-box-shadow: 0 0 0 50px rgba(0, 255, 0, 0.1) inset !important;
  -webkit-text-fill-color: #fff;
}
