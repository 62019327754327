.nav {
  position: fixed;
  z-index: 300;
  width: 100vw;
  min-width: 100%;
  bottom: 0;
  right: 0;
  height: 4.1rem;
  background-color: #020c09;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  &-btn {
    all: unset;
    border: none;
    width: 4.5rem;
    height: 4.1rem;
    max-width: 100%;
    color: $light-font;
    display: flex;
    flex-direction: column;
    place-self: center;
    & a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    p {
      font-size: 0.625rem;
      font-weight: 400;
      color: #6c7270;
      margin: 0;
      text-align: center;
    }
  }
}

.nav {
  &-btn {
    .nav-link--active {
      p {
        color: $green;
      }
      .nav-icon path {
        fill: $green;
      }
    }
  }
}

@media screen and (max-height: 500px) {
  .nav {
    height: 3.3rem;
    &-btn {
      height: 3.3rem;
    }
  }
}
@media screen and (max-height: 414px) {
  .nav {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 100vh;
    min-height: 100%;
    width: 4rem;
    min-width: 4rem;
    flex-direction: column-reverse;
  }
}
