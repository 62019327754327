.succes {
  height: fit-content;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $box-shadow;
  border: 2px solid rgb(13, 13, 13);
  border-radius: 20px;
  background-color: #707070;

  &-header {
    color: #fff;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    text-shadow: $box-shadow;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    font-size: 2.5rem;
    font-weight: 900;
    letter-spacing: 0.1rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }
  &-message {
    color: rgb(23, 23, 23);
    font-size: 0.9rem;
    //font-weight: 300;
    line-height: 1.4rem;
    padding: 0 1.4rem;
  }
  &-final-score {
    color: rgb(23, 23, 23);
    font-weight: 600;
  }
  &-redirect {
    all: unset;
    color: $light-font;
    text-transform: lowercase;
    font-size: 0.8rem;
    margin: 1.9rem 0 0.5rem;
  }
}
.submit-score-btn {
  height: 3.125rem;
  width: 12.875rem;
  border: 1px solid $green;
  box-shadow: $green-box-shadow;
  background: transparent;
  border-radius: 4px;
  color: $green;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 2px;
}
