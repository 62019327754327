@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;900&display=swap");

@import "./color-variables";

* {
  box-sizing: border-box;
}

@keyframes movimiento {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 392px 0;
  }
}

html,
body {
  text-align: center;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(151deg, #092c24, #010604);
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
}
a {
  color: $light-font;
  text-decoration: none;

  &:hover {
    color: $light-font;
  }
}
input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.background {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 100%;
  min-height: 100vh;
  width: 100vw;
  height: 100%;
  padding: 2rem 1.125rem 3rem;
}
.background-centered {
  justify-content: center;
  //padding: 2rem 1.125rem 2rem;
}

.main-btn {
  height: 3.125rem;
  width: 12.875rem;
  border: 1px solid $green;
  box-shadow: $green-box-shadow;
  background: transparent;
  border-radius: 4px;
  color: $green;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 2px;
}

.secondary-btn {
  all: unset;
  color: #fff;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  font-weight: 200;
  padding: 1rem;
}

.container {
  max-width: 500px;
  margin: 0 0 1rem;
}

.headline {
  color: #fff;
  font-weight: 200;
  text-transform: uppercase;
  text-shadow: 0 0 1px #fff, 0 0 3px #fff, 0 0 5px #fff;
  letter-spacing: 3px;
  font-size: 1.875rem;
}

.page-headline {
  color: #fff;
  font-weight: 200;
  font-size: 1rem;
}

.text-regular {
  color: #b0c7bc;
  font-size: 1rem;
  letter-spacing: 0.06rem;
  font-weight: 200;
}

@media screen and (max-height: 500px) {
  .background {
    padding: 0.4rem 0.5rem 4.2rem;
  }
  .headline {
    font-size: 1.4rem;
    margin: 0.4rem 0;
  }
  .container {
    margin: 0 0.2rem;
  }
  .text-regular {
    font-size: 0.8rem;
  }
}
