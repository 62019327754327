//color
$green: #00ff00;
$green-box-shadow: 0 0 6px #00ff00;
$background-color: linear-gradient(151deg, #092c24, #010604);

//font color
$light-font: rgb(161, 161, 161);

//drop-shadow
$box-shadow: 2px 6px rgb(13, 13, 13);
