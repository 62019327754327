.puzzle {
  padding: 1.125rem;
  margin: 0 auto;
  height: 100vw;
  width: 100vw;
  max-width: 500px;
  max-height: 500px;
  grid-template-columns: repeat(3, 32.33%);
  grid-template-rows: repeat(3, 32.33%);
  display: grid;
  gap: 1.5%;
  &-item {
    border-radius: 3px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    &-img {
      width: 300%;
      height: 300%;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      &nr0 {
        top: 0;
        left: 0;
      }
      &nr1 {
        left: -100%;
      }
      &nr2 {
        left: -200%;
      }
      &nr3 {
        top: -100%;
        left: 0%;
      }
      &nr4 {
        top: -100%;
        left: -100%;
      }
      &nr5 {
        top: -100%;
        left: -200%;
      }
      &nr6 {
        top: -200%;
        left: 0;
      }
      &nr7 {
        top: -200%;
        left: -100%;
      }
      &nr8 {
        top: -200%;
        left: -200%;
      }
    }
  }
}

.FillSquare {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  cursor: pointer;
  border: 2px solid transparent;
}

.EmptySquare {
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}
.square-rightPos {
  border: 2px solid $green;
  box-shadow: 0 0 4px #00ff00;
}

.puzzle-header {
  width: 100vw;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.125rem;
}
.puzzle-moves {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  min-width: 5.8rem;
  gap: 0.2rem;
  &-10 {
    min-width: 7.3rem;
  }
  &-100 {
    min-width: 8.7rem;
  }
  &-count {
    text-align: left;
    font-size: 3rem;
    font-weight: 200;
    color: $green;
    margin: 0;
    letter-spacing: 0.2rem;
    text-shadow: $green-box-shadow;
  }
  &-headline {
    color: $light-font;
    text-align: right;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    margin: 0;
  }
}

.puzzle-reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-btn {
    all: unset;
    color: #8ca39a;
    transform: scaleX(-1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-text {
    color: #8ca39a;
    font-size: 0.625rem;
    font-weight: 400;
    margin: 0;
  }
  &-icon {
    color: #8ca39a;
    svg {
      fill: #8ca39a;
    }
  }
}

//css for changing map theme
.theme-btn-container {
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  background-color: rgba(118, 118, 118, 0.2);
  border-radius: 6px;
  height: 5.25rem;
  gap: 0.2rem;
}
.theme-btn {
  all: unset;
  flex: 2;
  height: 100%;
  color: #8ca39a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.theme-title-container {
  all: unset;
  color: #fff;
  border-radius: 6px;
  flex: 6;
  padding: 0.4rem;
  border: 2px solid transparent;
  cursor: pointer;
}
.theme-title-container--active {
  border: 2px solid $green;
  box-shadow: $green-box-shadow;
  background-color: rgba(0, 255, 0, 0.1);
}

.theme-btn-icon {
  color: #8ca39a;
  &:active {
    color: $light-font;
  }
}
.theme-title {
  font-size: 0.9rem;
  font-weight: 300;
  margin: 0.2rem 0;
}
.theme-title-showmap-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 1.2rem;
  margin: 0.2rem;
  gap: 0.2rem;
}
.theme-title-showmap {
  margin: 0;
  color: $green;
  font-weight: 400;
  font-size: 0.688rem;
  padding-bottom: 2px;
}

//css for flip animation on puzzlecontainer
.puzzle-container.flip {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
.puzzle-container {
  position: relative;
  z-index: 50;
  top: 0;
  height: 100vw;
  width: 100vw;
  max-width: 500px;
  max-height: 500px;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transition: 150ms;
  transform: rotateY(0);
  -webkit-transform: rotateY(0);
  perspective: 1000px;
  -webkit-perspective: 1000px;
}

.puzzle-container .front {
  left: 0;
}
.puzzle-container .back,
.puzzle-container .front {
  position: absolute;
  visibility: visible;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 0;
  -webkit-perspective: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}
.puzzle-container .back {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
.full-map-container {
  padding: 1.125rem;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  border-radius: 7px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  .full-map {
    grid-area: 1 / 1 / -1 / -1;
    max-width: 100%;
    border-radius: 3px;
    width: fit-content;
    height: auto;
  }
  .full-map-label {
    grid-area: 1 / 1 / -1 / -1;
    place-self: center;
    align-self: flex-end;
    background-color: rgba(255, 255, 255, 0.4);
    height: 1.2rem;
    width: fit-content;
    margin: 0.4rem;
    padding: 0.1rem 0.3rem;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    &-link {
      color: #414141;
      font-size: 9px;
      font-weight: 600;
    }
    &-attr {
      font-size: 9px;
      font-weight: 400;
    }
  }
}

@media screen and (max-height: 500px) {
  .puzzle {
    padding: 0.5rem;
  }
  .full-map-container {
    padding: 0.5rem;
  }
  .puzzle-moves {
    &-count {
      font-size: 2.6rem;
    }
    &-headline {
      font-size: 0.8rem;
    }
  }
  .puzzle-header {
    padding: 0 0.5rem;
  }
  .puzzle-reset {
    &-text {
      font-size: 0.6rem;
    }
  }
  .theme-btn-container {
    margin: 0 0;
  }
}
@media screen and (max-height: 414px) {
  .puzzle,
  .puzzle-container {
    max-height: 350px;
    max-width: 350px;
  }

  .puzzle-header {
    width: 5rem;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
  .screenorientation-landscape {
    height: 100%;
    max-height: 100%;
    flex-direction: row;
    max-width: 100vw;
    width: 100%;
    padding: 0 3rem 0 0;
  }
  .puzzle-moves {
    flex-direction: column;
    align-items: center;
    &-count {
      font-size: 2rem;
    }
  }
  .theme-btn-container {
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    width: 7rem;
  }
  .theme-title {
    word-break: break-word;
  }
  .theme-title-container {
    flex: 4;
    height: 6rem;
    max-height: 12rem;
    min-height: 12rem;
    width: 5rem;
  }
  [aria-label="skip to next map theme"] {
    transform: rotate(90deg);
  }
  [aria-label="skip to previous map theme"] {
    transform: rotate(90deg);
  }
}

// .theme-btn-container {
//   flex-direction: column;
//   height: 100%;
//   min-height: 100vh;
//   width: 7rem;
//   margin: 0.3rem 0;
// }
