.scores-loader {
  padding: 20% 0;
  max-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scores-container {
  width: 100%;
  max-width: 500px;
}
.scores {
  &-header {
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.6rem;
      gap: 0.6rem;
      cursor: pointer;
      &-level {
        font-size: 0.9rem;
        font-weight: 400;
        color: #8ca39a;
        &.active {
          color: $green;
          font-size: 0.9rem;
          font-weight: 400;
        }
      }
    }
  }
}
.scores-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  //gap: 2.5rem;
  padding: 0 1rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 200;
  background-color: rgba(63, 84, 78, 0.4);
  &:nth-child(even) {
    background-color: rgba(63, 84, 78, 0.6);
  }
}
.arrow-up {
  height: 0px;
  width: 0px;
  position: relative;
  left: 25%;
  border-right: solid 10px transparent;
  border-left: solid 10px transparent;
  border-bottom: solid 10px #182e29;
  &.left {
    left: 75%;
  }
}
.scores-content-container {
  overflow: hidden;
  width: 100%;
}
.scores-content {
  border-radius: 6px;
  max-height: 69vh;
  overflow-y: scroll;
}
.scores-content-header {
  color: #8ca39a;
  z-index: 200;
  background-color: #182e29;
  position: sticky;
  top: 0;
}
.scores-item-placement {
  text-align: left;
  padding-right: 1rem;
}
.scores-item-score {
  text-align: right;
  padding: 0 0.4rem 0;
  flex: 2;
}
.scores-item-name {
  text-align: left;
  padding-left: 1rem;
  flex: 4;
}

@media screen and (max-height: 400px) {
  .scores-loader {
    padding: 5% 0;
  }
}
